import React, { Component } from "react";
import EditAssessmentForm from "../../component/assessment/edit-assessment-form";
import { GetError, authToken } from "../../component/action";
import API from "../../api";
import logout from "../../component/dashboard/logout";
import { toast } from "react-toastify";
class EditAssessment extends Component {
  constructor(props) {
    super(props);
    this.state = {
        IsLoading: false,
        IsSubmit: false
    };
  }

  updateAssessment = data => {
    let self = this;
    this.setState({
      IsLoading: true,
      IsSubmit: true
    });
    let assessmentParam = new FormData();    
    assessmentParam.append("assessment_name", data.assessment_name);
    API.post(`item/assessment_edit/${data.assessment_id}`, assessmentParam)
      .then(res => {
        if (res.data.status === 200) {
          this.setState({
            IsLoading: false,
            IsSubmit: false
          });

          this.props.history.push("/assessmentlist");
          toast.success(res.data.message);
        } else {
          toast.error("Something went wrong on server end.");
        }
      })
      .catch(function(error) {
        let errorobj = GetError(error);
        if (errorobj.status === 400) {
          toast.error(errorobj.data.message);
        } else if (errorobj.data.status === 401) {
          toast.error(errorobj.data.message);
          setTimeout(function() {
            logout();
          }, 2000);
        } else {
          toast.error("Something went wrong on server end.");
        }

        self.setState({
          IsLoading: false,
          IsSubmit: false
        });
      });
  };
  render() {
    if (authToken==="") {
      this.props.history.push("/login");
    }
    return (
      <EditAssessmentForm
        updateAssessment={this.updateAssessment}
        id={this.props.match.params.id}
        propsState={this.state}
      />
    );
  }
}

export default EditAssessment;
