import axios from "axios";
import { serverURL, appVersion } from "./component/action";

const instance = axios.create({
  baseURL: serverURL,
  responseType: "json",
  timeout: 500000
});

instance.defaults.headers.common["devicetype"] = "3";
instance.defaults.headers.common["appversion"] = appVersion;

// Add a request interceptor
instance.interceptors.request.use(
  function(config) {
    const token = localStorage.getItem("token");
    config.headers.accesstoken = token;
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);
export default instance;