import React from 'react';
import { NavLink } from 'react-router-dom';
const baseURL = '/';
let serverURL = '';
let assetsURL = '';

serverURL = 'https://itemconnection.ardmsskills.org/api/v1/';
assetsURL = 'https://itemconnection.ardmsskills.org/';

// serverURL = 'https://itemideaapp.galaxyweblinks.com/api/v1/';
// assetsURL = 'https://itemideaapp.galaxyweblinks.com/';

//11111 serverURL = 'http://www.itemweb.local/api/v1/';
//222222 assetsURL = 'http://www.itemweb.local/';

 //serverURL = 'http://php72.gwl.com/itemideaapp/api/v1/';
 //assetsURL = 'http://php72.gwl.com/itemideaapp/api/';

//serverURL = 'http://localhost:81/itemideaapp/dev/api/v1/';
//assetsURL = 'http://localhost:81/itemideaapp/dev/';

let user = localStorage.getItem('userdata');
const userData = user ? JSON.parse(user) : '';
const authToken = localStorage.getItem('token');
const appVersion = '1.0';

const GetError = function getError(error) {
  if (error.response) {
    let response = {
      type: 'response',
      status: error.response.status,
      headers: error.response.headers,
      data: error.response.data
    };
    return response;
  } else if (error.request) {
    return { type: 'request', request: error.request };
  } else {
    return { type: 'message', message: error.message };
  }
};



const Pagination = function Pagination(props) {
  let Pages = Array(props.noOfPages).fill(null);
  let links =
    props.noOfPages > 2 && props.currentPage < 2 ? (
      <React.Fragment>
        <li
          className={
            props.currentPage === 1
              ? 'paginate_button page-item active'
              : 'paginate_button page-item'
          }
        >
          <NavLink to='#' onClick={() => props.gotoPage(1, props.noOfPages)}>
            1
          </NavLink>
        </li>
        <li
          className={
            props.currentPage === 2
              ? 'paginate_button page-item active'
              : 'paginate_button page-item'
          }
        >
          <NavLink to='#' onClick={() => props.gotoPage(2, props.noOfPages)}>
            2
          </NavLink>
        </li>
        {props.noOfPages > 3 ? (
          <li className='paginate_button page-item'>...</li>
        ) : (
          ''
        )}

        <li
          className={
            props.currentPage === props.lastPage
              ? 'paginate_button page-item active'
              : 'paginate_button page-item'
          }
        >
          <NavLink
            to='#'
            onClick={() => props.gotoPage(props.lastPage, props.noOfPages)}
          >
            {props.lastPage}
          </NavLink>
        </li>
      </React.Fragment>
    ) : props.noOfPages <= 2 ? (
      Pages.map((key, index) => {
        return (
          <li
            className={
              props.currentPage === index + 1
                ? 'paginate_button page-item active'
                : 'paginate_button page-item'
            }
            key={index + 1}
          >
            <NavLink
              to='#'
              onClick={() => props.gotoPage(index + 1, props.noOfPages)}
            >
              {index + 1}
            </NavLink>
          </li>
        );
      })
    ) : props.currentPage >= 2 ? (
      <React.Fragment>
        {props.currentPage > 2 ? (
          <li
            className={
              props.currentPage === 1
                ? 'paginate_button page-item active'
                : 'paginate_button page-item'
            }
          >
            <NavLink to='#' onClick={() => props.gotoPage(1, props.noOfPages)}>
              1
            </NavLink>
          </li>
        ) : (
          ''
        )}
        {props.currentPage > 3 ? (
          <li className='paginate_button page-item'>...</li>
        ) : (
          ''
        )}

        <li
          className={
            props.currentPage === props.currentPage - 1
              ? 'paginate_button page-item active'
              : 'paginate_button page-item'
          }
        >
          <NavLink
            to='#'
            onClick={() =>
              props.gotoPage(props.currentPage - 1, props.noOfPages)
            }
          >
            {props.currentPage - 1}
          </NavLink>
        </li>
        {/* <li
          className={
            props.currentPage === props.currentPage
              ? 'paginate_button page-item active'
              : 'paginate_button page-item'
          }
        > */}
        <li className='paginate_button page-item active'>
          <NavLink
            to='#'
            onClick={() => props.gotoPage(props.currentPage, props.noOfPages)}
          >
            {props.currentPage}
          </NavLink>
        </li>
        {props.currentPage < props.noOfPages ? (
          <li
            className={
              props.currentPage === props.currentPage + 1
                ? 'paginate_button page-item active'
                : 'paginate_button page-item'
            }
          >
            <NavLink
              to='#'
              onClick={() =>
                props.gotoPage(props.currentPage + 1, props.noOfPages)
              }
            >
              {props.currentPage + 1}
            </NavLink>
          </li>
        ) : (
          ''
        )}

        {props.currentPage + 2 >= props.noOfPages ? (
          ''
        ) : (
          <li className='paginate_button page-item'>...</li>
        )}
        {props.currentPage + 1 >= props.noOfPages ? (
          ''
        ) : (
          <li
            className={
              props.currentPage === props.lastPage
                ? 'paginate_button page-item active'
                : 'paginate_button page-item'
            }
          >
            <NavLink
              to='#'
              onClick={() => props.gotoPage(props.lastPage, props.noOfPages)}
            >
              {props.lastPage}
            </NavLink>
          </li>
        )}
      </React.Fragment>
    ) : (
      ''
    );
  return (
    <ul className='pagination'>
      <li
        className={
          props.prevPage == null
            ? 'paginate_button page-item previous disabled'
            : 'paginate_button page-item previous'
        }
      >
        <NavLink
          to='#'
          onClick={() => props.gotoPage(props.prevPage, props.noOfPages)}
        >
          Prev
        </NavLink>
      </li>
      {links}
      <li
        className={
          props.nextPage == null
            ? 'paginate_button page-item next disabled'
            : 'paginate_button page-item next'
        }
      >
        <NavLink
          to='#'
          onClick={() => props.gotoPage(props.nextPage, props.noOfPages)}
        >
          Next
        </NavLink>
      </li>
    </ul>
  );
};

export {
  baseURL,
  serverURL,
  GetError,
  userData,
  authToken,
  appVersion,
  assetsURL,
  Pagination
};
