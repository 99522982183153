import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../dashboard/loader";
import "react-toastify/dist/ReactToastify.css";
import SimpleReactValidator from "simple-react-validator";
import API from "../../api";
import PropTypes from "prop-types";
class EditAssessmentForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      assessment_id: "",
      assessment_name: "",
      Isloading: this.props.propsState.IsLoading,
      IsSubmit: this.props.propsState.IsSubmit
    };
    this.validator = new SimpleReactValidator();
  }
  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }
  componentWillMount() {
    let self = this;
    this.setState({
      Isloading: true
    });
    API.get(`item/assessment/${this.props.id}`)
      .then(res => {
        this.setState({
          assessment_id: res.data.data.assessment.assessment_id,
          assessment_name: res.data.data.assessment.assessment_name,
          Isloading: false
        });
      })
      .catch(function(error) {
        toast.error("Something went wrong on server end.");
        self.setState({
          Isloading: false
        });
      });
  }
  updateAssessment(e) {
    e.preventDefault();
    if (this.validator.allValid()) {
      this.props.updateAssessment(this.state);
    } else {
      this.validator.showMessages();
      this.forceUpdate();
      this.setState({
        Isloading: false,
        IsSubmit: false
      });
    }
  }

  render() {
    return (
      <div>
        <Loader IsLoading={this.state.Isloading} />
        <div className="page-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb" style={{ backgroundColor: "#fff", padding:"8px 0px 8px 15px" } }>
                    <li className="breadcrumb-item"><NavLink to="/assessmentlist">Assessment List</NavLink></li>
                    <li className="breadcrumb-item active" aria-current="page">Edit Assessment</li>
                  </ol>
                </nav>
                <div className="card">
                  <h4 className="card-title">Edit Assessment</h4>
                  <div className="col-md-6">
                    <form className="form-horizontal">
                      <div className="card-body">
                        <div className="form-group row">
                          <div className="col-sm-9">
                            <label className="control-label col-form-label">
                            Assessment Title <sup>*</sup>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="fname"
                              placeholder="Enter assessment title"
                              value={this.state.assessment_name}
                              name="assessment_name"
                              onChange={e => this.handleChange(e)}
                            />
                            {this.validator.message(
                              "Assessment title",
                              this.state.assessment_name,
                              "required|min:2|max:100"
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="border-top">
                        <div className="card-body">
                          <button
                            type="button"
                            className="btn btn-primary-newui btn-md-newui"
                            disabled={this.state.IsSubmit}
                            onClick={e => this.updateAssessment(e)}
                          >
                            Save
                          </button>

                          <NavLink
                            to="/assessmentlist"
                            type="button"
                            className="btn btn-primary-newui btn-md-newui"
                          >
                            Cancel
                          </NavLink>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
EditAssessmentForm.propTypes = {
  updateAssessment: PropTypes.func
};
export default EditAssessmentForm;
