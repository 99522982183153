import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import PropTypes from 'prop-types';
import Loader from '../dashboard/loader';
import { Multiselect } from 'multiselect-react-dropdown';
import API from '../../api';
import { toast } from 'react-toastify';
class AddUserForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname       : '',
      lastname        : '',
      email           : '',
      password        : '',
      armdsid         : '',
      gender          : '',
      phone           : '',
      certifications  : [],
      specialties     : [],
      working_group : [],
      certifications_selected:[],
      specialties_selected     : [],
      working_group_selected : [],
      isLoad: this.props.isLoad
    };
    this.validator = new SimpleReactValidator();
  }

  componentWillMount() {
    let self = this;
    this.setState({
      isLoad: true
    });
    API.get(`user/swgroup_all`)
      .then(res => {
        console.log(res);
        this.setState({
          certifications: res.data.data.certList,
          specialties: res.data.data.specialty,
          working_group: res.data.data.working_group,
          isLoad: false
        });
      })
      .catch(function(error) {
        toast.error('Something went wrong on server end.');
        self.setState({
          isLoad: false
        });
      });
  }  

  SubmitUser(e) {
    e.preventDefault();
    if (this.validator.allValid()) {
      this.props.SubmitUser(e, this.state);
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  handlechange(e) {
    this.setState({
        [e.target.name]: e.target.value
      });
  }

  onSelect(e, name) {
    
    if(name === 'certifications')
    {
      this.setState({
        certifications_selected: e
      });
    }

    if(name === 'specialties')
    {
      let specialties_selected = [];
      if(e.length > 0)
      {
        for(let i=0; i<e.length; i++)
        {
          specialties_selected.push(e[i]['id']);
        }
      }
      this.setState({
        specialties_selected: specialties_selected
      });
    }

    if(name === 'working_group')
    {
      let working_group_selected = [];
      if(e.length > 0)
      {
        for(let i=0; i<e.length; i++)
        {
          working_group_selected.push(e[i]['id']);
        }
      }
      this.setState({
        working_group_selected: working_group_selected
      });
    }
    
  }
 
  onRemove(e, name) {

    if(name === 'certifications')
    {
      this.setState({
        certifications_selected: e
      });
    }

    if(name === 'specialties')
    {
      let specialties_selected = [];
      if(e.length > 0)
      {
        for(let i=0; i<e.length; i++)
        {
          specialties_selected.push(e[i]['id']);
        }
      }
      this.setState({
        specialties_selected: specialties_selected
      });
    }

    if(name === 'working_group')
    {
      let working_group_selected = [];
      if(e.length > 0)
      {
        for(let i=0; i<e.length; i++)
        {
          working_group_selected.push(e[i]['id']);
        }
      }
      this.setState({
        working_group_selected: working_group_selected
      });
    }
  }

  render() {
    return (
      <div>
        <Loader isLoading={this.props.propsState.isLoad} />
        <div className='page-wrapper'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='card'>
                  <h4 className='card-title'> Add User</h4>
                  <div className='col-md-10'>
                    <form className='form-horizontal'>
                      <div className='card-body'>
                        <div className='form-group row'>
                          <div className='col-sm-4'>
                            <label className='text-left control-label col-form-label label-text'>
                              Name<sup className='req-color'>*</sup>
                            </label>
                            <input
                              type='text'
                              className='form-control custom-input'
                              id='fname'
                              value={this.state.firstname}
                              name='firstname'
                              onChange={e => this.handlechange(e)}
                              placeholder='Enter the name'
                            />
                            {this.validator.message(
                              'Name',
                              this.state.firstname,
                              'required|alpha_space|min:1|max:100'
                            )}
                          </div>

                          <div className='col-sm-4'>
                            <label className='control-label col-form-label label-text'>
                              Nick Name<sup className='req-color'>*</sup>
                            </label>
                            <input
                              type='text'
                              className='form-control custom-input'
                              id='lname'
                              value={this.state.lastname}
                              name='lastname'
                              onChange={e => this.handlechange(e)}
                              placeholder='Enter nick name'
                            />
                            {this.validator.message(
                              'Nick Name',
                              this.state.lastname,
                              'required|alpha_space|min:2|max:100'
                            )}
                          </div>

                          <div className='col-sm-4'>
                            <label className='control-label col-form-label label-text'>
                              Email<sup className='req-color'>*</sup>
                            </label>
                            <input
                              type='email'
                              className='form-control custom-input'
                              id='email'
                              value={this.state.email}
                              name='email'
                              onChange={e => this.handlechange(e)}
                              placeholder='Enter email address'
                            />
                            {this.validator.message(
                              'Email',
                              this.state.email,
                              'required|email|min:2|max:100'
                            )}
                          </div>
                        </div>
                        <div className='form-group row'>
                          <div className='col-sm-4'>
                            <label className='control-label col-form-label label-text'>
                              ARDMS ID<sup className='req-color'>*</sup>
                            </label>
                            <input
                              type='text'
                              className='form-control custom-input'
                              id='ardmsid'
                              placeholder='Enter ARDMS ID'
                              value={this.state.armdsid}
                              name='armdsid'
                              onChange={e => this.handlechange(e)}
                            />
                            {this.validator.message(
                              ' Ardms Id',
                              this.state.armdsid,
                              'required|numeric|min:1|max:10'
                            )}
                          </div>
                          <div className='col-sm-4'>
                            <label className='control-label col-form-label label-text'>
                              Phone<sup className='req-color'>*</sup>
                            </label>
                            <input
                              type='text'
                              className='form-control custom-input'
                              id='phone'
                              placeholder='Enter phone number'
                              value={this.state.phone}
                              name='phone'
                              onChange={e => this.handlechange(e)}
                            />
                            {this.validator.message(
                              ' Phone',
                              this.state.phone,
                              'required|phone'
                            )}
                          </div>
                          <div className='col-sm-4'>
                            <label className='control-label col-form-label label-text'>
                              Gender<sup className='req-color'>*</sup>
                            </label>
                            <select
                              id="gender"
                              name="gender"
                              style={{backgroundImage: "none"}}
                              placeholder="Please select the gender"
                              className='form-control'
                              onChange={e => this.handlechange(e)} 
                            >
                              <option value="">Please select the gender</option>
                              <option value="1">Male</option>
                              <option value="2">Female</option>
                            </select>
                            {this.validator.message(
                              ' Gender',
                              this.state.gender,
                              'required'
                            )}
                          </div>  
                        </div>
                        <div className='form-group row'>
                          <div className='col-sm-4'>
                            <label className='control-label col-form-label label-text'>
                              Certifications
                            </label>
                            <Multiselect 
                              name="certifications" 
                              id="certifications" 
                              options={this.state.certifications} 
                              isObject={false}
                              onSelect={e => this.onSelect(e,'certifications')} 
                              onRemove={e => this.onRemove(e,'certifications')}
                            />
                            {/* {this.validator.message(
                              ' Certifications',
                              this.state.armdsid,
                              'required'
                            )} */}
                          </div>
                          <div className='col-sm-4'>
                            <label className='control-label col-form-label label-text'>
                              Specialties
                            </label>
                            <Multiselect 
                              name="specialties" 
                              id="specialties" 
                              options={this.state.specialties} 
                              displayValue="name" 
                              dataItemKey="id"
                              onSelect={e => this.onSelect(e,'specialties')} 
                              onRemove={e => this.onRemove(e,'specialties')} 
                            />
                            {/* {this.validator.message(
                              ' Specialties',
                              this.state.phone,
                              'required'
                            )} */}
                          </div>
                          <div className='col-sm-4'>
                            <label className='control-label col-form-label label-text'>
                              Working Group
                            </label>
                            <Multiselect 
                              name="working_group" 
                              id="working_group" 
                              options={this.state.working_group} 
                              displayValue="name"
                              onSelect={e => this.onSelect(e,'working_group')} 
                              onRemove={e => this.onRemove(e,'working_group')}
                            />
                            {/* {this.validator.message(
                              ' Working Group 1',
                              this.state.gender,
                              'required'
                            )} */}
                          </div>  
                        </div>
                      </div>
                      <div className='border-top'>
                        <div className='card-body'>
                          <button
                            type='submit'
                            onClick={e => this.SubmitUser(e)}
                            className='btn btn-primary-newui btn-md-newui'
                            disabled={this.props.propsState.Issubmit}
                          >
                            Save
                          </button>

                          <NavLink
                            to='/userslist'
                            type='button'
                            className='btn btn-primary-newui btn-md-newui'
                          >
                            Cancel
                          </NavLink>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
AddUserForm.propTypes = {
  SubmitUser: PropTypes.func
};
export default AddUserForm;
