import { combineReducers } from "redux";
import LoadAssessment from "./assessment-reducer";
import loadUsers from "./users-reducer";
import UserAction from "./user-ruducer";
const allRuducer = combineReducers({
  LoadAssessment,
  loadUsers,
  UserAction
});
export default allRuducer;
