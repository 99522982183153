import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
const SideNavbar = props => {
  return (
    <aside
      className='left-sidebar'
      data-sidebarbg='skin5'
      onMouseEnter={() => props.hoverNav(true)}
      onMouseLeave={() => props.hoverNav(false)}
    >
      <div className='scroll-sidebar'>
        <nav className='sidebar-nav'>
          <ul id='sidebarnav' className='p-t-30'>
            <li className='sidebar-item'>
              <a href
                className={props.isActive('Users')}
                onClick={e => props.toggleNav('Users')}
                aria-expanded='false'
              >
                <i className='fas fa-users' />
                <span className='hide-menu'>Users </span>
              </a>
              <ul aria-expanded='false' className={props.isActiveUl('Users')}>
                <li className='sidebar-item'>
                  <NavLink
                    to='/userslist'
                    className='sidebar-link'
                    activeClassName='activechild'
                  >
                    <i className='fas fa-users' />
                    <span className='hide-menu'> Users List </span>
                  </NavLink>
                </li>
                <li className='sidebar-item'>
                  <NavLink
                    to='/adduser'
                    className='sidebar-link'
                    activeClassName='activechild'
                  >
                    <i className='fas fa-user' />
                    <span className='hide-menu'> Add User </span>
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className='sidebar-item'>
              <a href
                className={props.isActive('Assessment')}
                onClick={e => props.toggleNav('Assessment')}
                aria-expanded='false'
              >
                <i className='fas fa-list-alt' />
                <span className='hide-menu'>Assessment </span>
              </a>
              <ul
                aria-expanded='false'
                className={props.isActiveUl('Assessment')}
              >
                
                <li className='sidebar-item'>
                  <NavLink
                    to='/assessmentlist'
                    className='sidebar-link'
                    activeClassName='activechild'
                  >
                    <i className='fas fa-calendar-alt' />
                    <span className='hide-menu'> Assessment List </span>
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className='sidebar-item'>
              <a href
                className={props.isActive('Items')}
                onClick={e => props.toggleNav('Items')}
                aria-expanded='false'
              >
                <i className='fas fa-list-alt' />
                <span className='hide-menu'>Items </span>
              </a>
              <ul
                aria-expanded='false'
                className={props.isActiveUl('Items')}
              >
                <li className='sidebar-item'>
                  <NavLink
                    to='/itemlist'
                    className='sidebar-link'
                    activeClassName='activechild'
                  >
                    <i className='fas fa-tasks' />
                    <span className='hide-menu'> Item List </span>
                  </NavLink>
                </li>
              </ul>
            </li>

            <li className='sidebar-item'>
              <a href
                className={props.isActive('ContactInfo')}
                onClick={e => props.toggleNav('ContactInfo')}
                aria-expanded='false'
              >
                <i className='fas fa-list-alt' />
                <span className='hide-menu'>Contact Info </span>
              </a>
              <ul
                aria-expanded='false'
                className={props.isActiveUl('ContactInfo')}
              >
                <li className='sidebar-item'>
                  <NavLink
                    to='/contactinfolist'
                    className='sidebar-link'
                    activeClassName='activechild'
                  >
                    <i className='fas fa-tasks' />
                    <span className='hide-menu'> Contact Info List </span>
                  </NavLink>
                </li>
              </ul>
            </li>

          </ul>
        </nav>
      </div>
    </aside>
  );
};
SideNavbar.propTypes = {
  toggleNav: PropTypes.func,
  isActive: PropTypes.func,
  isActiveUl: PropTypes.func
};
export default SideNavbar;
