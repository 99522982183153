import API from "../api";
import { GetError } from "../component/action";
export const GET_ALL_USERS = "GetAllUsers";
export function GetAllUsers(category_id) {
  return async function(dispatch, getState) {
    await API.get(`user/users`)
      .then(res => {
        dispatch({ type: GET_ALL_USERS, payload: res });
      })
      .catch(function(error) {
        dispatch({ type: "DEFAULT_STATE" });
        let errorObj = GetError(error);
        if (errorObj.status === 400) {
          console.log(errorObj.data.message);
        } else if (errorObj.status === 401) {
          console.log(errorObj.data.message);
        } else {
          console.log(errorObj.data.message);
        }
      });
  };
}
