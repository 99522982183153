import React from "react";
import { NavLink } from "react-router-dom";
const PageNotFound = props => {
  return (
    <div className="error-box">
      <div className="error-body text-center">
        <h1 className="error-title text-danger">404</h1>
        <h3 className="text-uppercase error-subtitle">PAGE NOT FOUND !</h3>
        <p className="text-muted m-t-30 m-b-30">
          YOU SEEM TO BE TRYING TO FIND HIS WAY HOME
        </p>
        <NavLink to="/" className="btn btn-primary-newui btn-md-newui">
          Back to home
        </NavLink>
      </div>
    </div>
  );
};

export default PageNotFound;
