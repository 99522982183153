import React, { Component } from 'react';
import ItemListCustom from '../../component/item/item-list-custom';
import logout from '../../component/dashboard/logout';
import { GetError,assetsURL } from '../../component/action';
import API from '../../api';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { GetAllAssessment } from '../../action/assessment-action';
import { GetAllUsers } from '../../action/users-action';
import moment from 'moment'
class ItemList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item_data: [],
      modalIsOpen: false,
      modelContent: '',
      modelContentType: '',
      quizlistoption: [],
      page_size: 10,
      resultCount: null,
      noOfPages: 1,
      currentPage: 1,
      lastPage: 0,
      prevPage: null,
      nextPage: null,
      pagination: '',
      searchTerm: '',
      sortName: 'stem',
      sortOrder: 'asc',
      isLoading: false,
      filter_assessment: 0,
      filter_user: 0,
      filter_status: 0,  
      startDate: '',
      endDate: '',
      postStartDate: '',
      postEndDate: ''    
    };
  }
  openModal = (value, type) => {
    this.setState({
      modalIsOpen: true,
      modelContent: value,
      modelContentType: type
    });
  };

  componentWillMount() {
    this.loadItemList();
    this.props.dispatch(GetAllAssessment());
    this.props.dispatch(GetAllUsers());
    this.props.dispatch({ type: 'DEFAULT_STATE' });
  }

  changeStatus = (question_id, status) => {
    let self = this;
    this.setState({
      isLoading: true
    });
    let params = new FormData();
    params.append('status', status);

    API.post(`item/update_status/${question_id}`, params)
      .then(res => {
        if (res.status === 200) {
          if (status === 1) {
            toast.success('Item is active.');
          } else {
            toast.success('Item is inactive.');
          }
        } else {
          toast.error('Something went wrong on server end.');
        }
        this.setState(
          {
            isLoading: false,
            item_data: []
          },
          () => {
            this.loadItemList();
          }
        );
      })
      .catch(function(error) {
        let errorObj = GetError(error);
        if (errorObj.status === 400) {
          if (self.state.quizlistoption.length > 0) {
            toast.error(errorObj.data.message);
          }
        } else if (errorObj.data.status === 401) {
          toast.error(errorObj.data.message);
          setTimeout(function() {
            logout(this.props);
          }, 2000);
        } else {
          toast.error('Something went wrong on server end.');
        }
        self.setState({
          isLoading: false
        });
      });
  };
 
  loadItemList() {
    let self = this;
    this.setState({
      isLoading: true,
      item_data: [],
      excel_data:[],
      resultCount: null,
      noOfPages: 1,
      nextPage: null,
      prevPage: null,
      lastPage: 0
    });

    setTimeout(() => {
      API.get(
        `item/all_items/${
          this.state.filter_assessment ? this.state.filter_assessment : 0
        }/${
          this.state.filter_user ? this.state.filter_user : 0
        }/${
          this.state.filter_status ? this.state.filter_status : 0
        }?order_by=${this.state.sortName}&order=${this.state.sortOrder}&limit=${
          this.state.page_size
        }&searchTerm=${this.state.searchTerm}&page=${this.state.currentPage}
        &startDate=${this.state.postStartDate}&endDate=${this.state.postEndDate}`
      )
        .then(res => {
          if (res.status === 200) {
            let nextUrl =
              res.data.data.nextlink !== '' ? res.data.data.nextlink : null;
            let prevUrl =
              res.data.data.prevlink !== ''
                ? res.data.data.prevlink === ''
                  ? 1
                  : res.data.data.prevlink
                : null;
            this.setState({
              item_data: res.data.data.item,
              excel_data: res.data.excel_data,
              resultCount: res.data.data.total_rows,
              isLoading: false,
              noOfPages: Math.ceil(
                res.data.data.total_rows / this.state.page_size
              ),
              nextPage: nextUrl,
              prevPage: prevUrl,
              lastPage: Math.ceil(
                res.data.data.total_rows / this.state.page_size
              )
            });
          }
        })
        .catch(function(erorr) {
          let errorobj = GetError(erorr);
          self.setState({
            isLoading: false
          });
          if (errorobj.status === 400) {
            self.setState({ item_data: [], excel_data:[] }, () => {
              if (self.state.item_data.length > 0) {
                toast.error(errorobj.data.message);
              }
            });
          } else if (errorobj.status === 401) {
            toast.error(errorobj.data.message);
            setTimeout(function() {
              logout(self.props);
            }, 2000);
          } else if (errorobj.status === 403) {
            toast.error(errorobj.data.message);
            setTimeout(function() {
              logout(self.props);
            }, 2000);
          } else {
            toast.error('Something went wrong on server end.');
          }
        });
    }, 100);
  }

  downloadMedia(item_id){
    setTimeout(() => {
      API.get(`item/download_media/${item_id}`)
      .then(res => {
        if (res.data.status === 200) {
          window.open(assetsURL+res.data.data);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch(function(error) { 
        let errorObj = GetError(error);
        if (errorObj.status === 400) {
          toast.error(errorObj.data.message);
        } else if (errorObj.data.status === 401) {
          toast.error(errorObj.data.message);
          setTimeout(function() {
            logout(this.props);
          }, 2000);
        } else {
          toast.error('Something went wrong on server end.');
        }
      });
    }, 100);
  }

  handleSort = (sortName, prevSortOrder) => {
    let sortOrder = prevSortOrder === 'asc' ? 'desc' : 'asc';
    this.setState({ sortName, sortOrder }, () => {
      this.loadItemList();
    });
  };

  handleChangeStartDate = date => {
    this.setState({
      startDate     :  date,
      postStartDate : moment(date).format('YYYY-MM-DD')
    }, () => {
      this.loadItemList();
    });
  };

  handleChangeEndDate = date => {
    this.setState({
      endDate     : date,
      postEndDate : moment(date).format('YYYY-MM-DD')
    }, () => {
      this.loadItemList();
    });
  };

  handleChange = event => {
    this.setState(
      {
        [event.target.name]: event.target.value,
        currentPage: 1,
        prevPage: null,
        nextPage: this.state.noOfPages > 1 ? 2 : null
      },
      () => {
        this.loadItemList();
      }
    );
  };
  gotoPage = (page, totalPages) => {
    if (page && page !== this.state.currentPage) {
      this.setState(
        {
          currentPage: page
        },
        () => {
          this.loadItemList();
        }
      );
    }
  };
  

  render() {
    return (
      <ItemListCustom
        propsState={this.state}
        afterOpenModal={this.afterOpenModal}
        closeModal={this.closeModal}
        handleChange={this.handleChange}
        handleChangeStartDate={this.handleChangeStartDate}
        handleChangeEndDate={this.handleChangeEndDate}
        handleSort={this.handleSort}
        openModal={this.openModal}
        changeStatus={this.changeStatus}
        gotoPage={this.gotoPage}
        assessment={this.props.load_assessment}
        users={this.props.load_user}
        downloadMedia={this.downloadMedia}
      />
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    load_assessment: state.LoadAssessment,
    load_user:state.loadUsers
  };
};
export default connect(mapStateToProps)(ItemList);
