import React, { Component } from "react";
import UserListCustom from "../../component/users/user-list-custom";
import API from "../../api";
import { confirmAlert } from "react-confirm-alert"; // Import
import { GetError } from "../../component/action";
import { toast } from "react-toastify";
import logout from "../../component/dashboard/logout";
class UserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      page_size: 10,
      resultCount: null,
      noOfPages: 1,
      currentPage: 1,
      lastPage: 0,
      prevPage: null,
      nextPage: null,
      pagination: "",
      searchTerm: "",
      sortName: "fullname",
      sortOrder: "asc",
      isLoading: false
    };
  }

  componentWillMount() {
    this.loadUserList();
  }

  loadUserList = () => {
    const self = this;
    this.setState({
      isLoading: true,
      users: [],
      resultCount: null,
      noOfPages: 1,
      nextPage: null,
      prevPage: null,
      lastPage: 0
    });

    setTimeout(() => {
      API.get(
        `/user/user_all?order_by=${this.state.sortName}&order=${this.state.sortOrder}&limit=${this.state.page_size}&searchTerm=${this.state.searchTerm}&page=${this.state.currentPage}`
      )
        .then(res => {
          if (res.status === 200) {
            let nextUrl =
              res.data.data.nextlink !== "" ? res.data.data.nextlink : null;
            let prevUrl =
              res.data.data.prevlink !== ""
                ? res.data.data.prevlink === ""
                  ? 1
                  : res.data.data.prevlink
                : null;
            this.setState({
              users: res.data.data.users,
              resultCount: res.data.data.total_rows,
              isLoading: false,
              noOfPages: Math.ceil(
                res.data.data.total_rows / this.state.page_size
              ),
              nextPage: nextUrl,
              prevPage: prevUrl,
              lastPage: Math.ceil(
                res.data.data.total_rows / this.state.page_size
              )
            });
          }
        })
        .catch(function(error) {
          let errorobj = GetError(error);
          self.setState({ isLoading: false });
          
          if (errorobj.data == null) {
            toast.error('No matching records found');
          } else  if (errorobj.status === 400) {
            self.setState({ users: [] });
            if (self.state.users.length > 0) {
              toast.error(errorobj.data.message);
            }
          } else if (errorobj.data.status === 401) {
            toast.error(errorobj.data.message);
          } else {
            toast.error("Something went wrong on server end.");
          }
        });
    }, 100);
  };

  handleSort = (sortName, prevSortOrder) => {
    let sortOrder = prevSortOrder === "asc" ? "desc" : "asc";
    this.setState({ sortName, sortOrder }, () => {
      this.loadUserList();
    });
  };
  handleChange = event => {
    this.setState(
      {
        [event.target.name]: event.target.value,
        currentPage: 1,
        prevPage: null,
        nextPage: this.state.noOfPages > 1 ? 2 : null
      },
      () => {
        this.loadUserList();
      }
    );
  };

  gotoPage = (page, totalPages) => {
    if (page && page !== this.state.currentPage) {
      this.setState(
        {
          currentPage: page
          //nextPage: totalPages > page ? page + 1 : null,
          //prevPage: page == 1 ? null : page - 1
        },
        () => {
          this.loadUserList();
        }
      );
    }
  };

  removeUser = user_id => {
    let self = this;
    this.setState({
      isLoading: true
    });
    confirmAlert({
      title: "Confirm",
      message: "Are you sure you want to delete this user?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            API.delete(`user/user/${user_id}`)
              .then(res => {
                if (res.data.status === 200) {
                  toast.success(res.data.message);
                  this.loadUserList();
                }
                this.setState({
                  isLoading: false
                });
              })
              .catch(function(error) {
                let errorObj = GetError(error);
                if (errorObj.status === 400) {
                  toast.error(errorObj.data.message);
                } else if (errorObj.data.status === 401) {
                  toast.error(errorObj.data.message);
                } else {
                  toast.error("Something went wrong on server end.");
                }

                self.setState({
                  isLoading: false
                });
              });
          }
        },
        {
          label: "No",
          onClick: () => {
            this.setState({
              isLoading: false
            });
          }
        }
      ]
    });
  };

  changeStatus = (user_id, status) => {
    let self = this;
    this.setState({
      isLoading: true
    });
    let params = new FormData();
    params.append("status", status);

    API.post(`user/user/${user_id}`, params)
      .then(res => {
        if (res.status === 200) {
          if (status === "1") {
            toast.success("Status is active.");
          } else if (status === "2") {
            toast.success("Status is inactive.");
          }
        }

        this.loadUserList();
        this.setState({
          isLoading: false
        });
      })
      .catch(function(error) {
        let errorObj = GetError(error);
        if (errorObj.status === 400) {
          toast.error(errorObj.data.message);
        } else if (errorObj.data.status === 401) {
          toast.error(errorObj.data.message);
          setTimeout(function() {
            logout(this.props);
          }, 2000);
        } else {
          toast.error("Something went wrong on server end.");
        }

        self.setState({
          isLoading: false
        });
      });
  };

  render() {
    return (
      <UserListCustom
        removeUser={this.removeUser}
        changeStatus={this.changeStatus}
        handleSort={this.handleSort}
        handleChange={this.handleChange}
        gotoPage={this.gotoPage}
        propsState={this.state}
      />
    );
  }
}

export default UserList;
