import React, { Component } from 'react';
import ChangePasswordForm from '../../component/dashboard/change-password-form';
import { GetError } from '../../component/action';
import API from '../../api';
import logout from '../../component/dashboard/logout';
import { toast } from 'react-toastify';
class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      IsLoading: false,
      IsSubmit: false,
      isUpdated: false
    };
  }

  SaveChangePassword = data => {
    let self = this;
    this.setState({
      IsLoading: true,
      IsSubmit: true
    });
    let params = new FormData();
    params.append('old_password', data.old_password);
    params.append('password', data.new_password);
    params.append('confirm_password', data.confirm_password);
    API.post('authentication/change_password', params)
      .then(res => {
        if (res.data.status === 200) {
          self.setState({
            IsLoading: false,
            IsSubmit: false,
            old_password: '',
            new_password: '',
            confirm_password: ''
          });
          toast.success('Password has been changed successfully.');
          self.forceUpdate();
          this.props.history.push('/');
        } else {
          toast.error('Something went wrong on server end.');
        }
      })
      .catch(function(error) {
        console.log('catch');
        let errorObj = GetError(error);
        if (errorObj.status === 400) {
          toast.error(errorObj.data.message);
        } else if (errorObj.data.status === 401) {
          toast.error(errorObj.data.message);
          setTimeout(function() {
            logout(this.props);
          }, 2000);
        } else {
          toast.error('Something went wrong on server end.');
        }
        self.setState({
          IsLoading: false,
          IsSubmit: false
        });
      });
  };

  render() {
    return (
      <ChangePasswordForm
        SaveChangePassword={this.SaveChangePassword}
        propsState={this.state}
      />
    );
  }
}

export default ChangePassword;
