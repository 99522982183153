import React, { Component } from "react";
import { toast } from "react-toastify";
import Loader from "../dashboard/loader";
import Modal from "react-modal";
import API from "../../api";
import logout from "../dashboard/logout";
import { assetsURL,GetError } from "../action";
import ReactTooltip from 'react-tooltip';
import { NavLink } from "react-router-dom";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    // marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  }
};
class ViewItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item_data: [],
      modalIsOpen: false,
      modelContent: '',
      modelContentType: '',
    }
  }

  openModal = (value, type) => {
    this.setState({
      modalIsOpen: true,
      modelContent: value,
      modelContentType: type
    });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  componentWillMount() {
    this.loadItemDetail();
  }

  downloadMedia(item_id){
    setTimeout(() => {
      API.get(`item/download_media/${item_id}`)
      .then(res => {
        if (res.data.status === 200) {
          window.open(assetsURL+res.data.data);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch(function(error) { 
        let errorObj = GetError(error);
        if (errorObj.status === 400) {
          toast.error(errorObj.data.message);
        } else if (errorObj.data.status === 401) {
          toast.error(errorObj.data.message);
          setTimeout(function() {
            logout(this.props);
          }, 2000);
        } else {
          toast.error('Something went wrong on server end.');
        }
      });
    }, 100);
  }

  loadItemDetail(){
    let self = this;
    this.setState({
      isLoading: true
    });
    API.get(`item/get_item?item_id=${this.props.match.params.id}`)
    .then(res => {

      if (res.status === 200) {
        this.setState({
          item_data: res
        });
      } else {
        toast.error('Something went wrong on server end.');
      }
      this.setState({
        isLoading: false
      });
    })
    .catch(function(erorr) {
      let errorobj = GetError(erorr);
      self.setState({
        isLoading: false
      });
      if (errorobj.status === 400) {
        self.setState({ item_data: [] }, () => {
          if (self.state.item_data.length > 0) {
            toast.error(errorobj.data.message);
          }
        });
      } else if (errorobj.status === 401) {
        toast.error(errorobj.data.message);
        setTimeout(function() {
          logout(self.props);
        }, 2000);
      } else if (errorobj.status === 403) {
        toast.error(errorobj.data.message);
        setTimeout(function() {
          logout(self.props);
        }, 2000);
      } else {
        toast.error('Something went wrong on server end.');
      }
    });
  }
  
  render() {
    const {data} = this.state.item_data;
    console.log(data?.data);
    return (
      <div>
      <Loader IsLoading={this.state.IsLoading} />

      <Modal isOpen={this.state.modalIsOpen} onAfterOpen={this.afterOpenModal} onRequestClose={this.closeModal}  style={customStyles} >
          <span className="fas fa-times closepopup" onClick={this.closeModal} />
          <div>
            {this.state.modelContentType === "IMAGE" && 
              <img className="model-size" src={this.state.modelContent} alt="Attachment"/>
              
            }
            {this.state.modelContentType === "VIDEO" &&
              <video className="model-size h-600" controls>
                <source src={this.state.modelContent} type="video/mp4" />
                <source src={this.state.modelContent} type="video/avi" />
              </video>
            }
          </div>
        </Modal>

      <div className="page-wrapper">
        <div className="container-fluid custom-form-design">
          <div className="row">
            <div className="col-md-12">            
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb" style={{ backgroundColor: "#fff", padding:"8px 0px 8px 15px" } }>
                  <li className="breadcrumb-item"><NavLink to="/itemlist">Item List</NavLink></li>
                  <li className="breadcrumb-item active" aria-current="page">Item Detail</li>
                </ol>
              </nav>

              <div className="card">
                <h4 className="card-title">Item Detail</h4>
                {data?.data.item_save_status !== '1' &&
                  <a className="view-page-download" onClick={() => this.downloadMedia(data?.data.item_id)} href>
                    <ReactTooltip />
                    <button className='btn btn-sm download-btn' data-tip='Download Media' data-place='top' data-effect='solid'>
                    Download Item Media
                    </button>
                  </a>    
                }                                    
                <div className="row">                  
                  <div className="col-md-6">
                    <div className="card-body">
                      <div className="form-group row">
                        <div className="col-sm-6" data-select2-id="11">
                          <label className="control-label col-form-label label-text">Assessment</label>
                          <p>{data?.data.assessment_name ? data?.data.assessment_name : "-" }</p>
                        </div>
                        <div className="col-md-6" data-select2-id="11">
                          <label className="control-label col-form-label label-text">Content Outline</label>
                          <p>{data?.data.content_outline ? data?.data.content_outline : "-"}</p>
                        </div>
                      </div>
                      {/* Question Start */}
                      <h5 className="card-title">Stem</h5>

                      <div className="form-group row">
                        <div className="col-sm-12">
                          <label className="control-label col-form-label label-text">Item</label>
                          <p>{data?.data.stem ? data?.data.stem : '-'}</p>
                        </div>
                      </div>
                      { data?.data.stem_media.length > 0 && 
                        <div className="form-group row">
                          <div className="col-sm-12">
                            <label className="control-label col-form-label label-text">Attachment</label>
                            <div className="attachment-img">
                              <ReactTooltip />
                              {data?.data.stem_media.length > 0 &&
                                data?.data.stem_media.map(
                                  (media) =>  media.item_media_type === '1' ?
                                  <img  src={assetsURL + "" + media.item_media_path} alt="attachment" onClick={() => this.openModal( assetsURL + "" + media.item_media_path,"IMAGE")} data-tip='Click to view image' data-place='top' data-effect='solid' />
                                  : media.item_media_type === '2' ? 
                                    <img  src="/assets/images/video.png" alt="attachment" onClick={() => this.openModal( assetsURL + "" + media.item_media_path,"VIDEO")} data-tip='Click to view video' data-place='top' data-effect='solid'/>
                                  : media.item_media_type === '3' ?                                  
                                  <div className="media-pdf">
                                    <a target="_blank" rel="noopener noreferrer" href={assetsURL + media.item_media_path}>
                                      <img  src="/assets/images/icon-pdf.svg" alt="attachment" data-tip='Click to open pdf' data-place='top' data-effect='solid'/>
                                    </a>
                                  </div>
                                  : ""
                                  )
                              }
                            </div>
                          </div>
                        </div>
                      }                      
                      {/* Question End */}
                      
                      {/* Explanation Start */}
                      <div className="form-group row">
                        <div className="col-sm-12">
                          <label className="control-label col-form-label label-text">Reference, Rationale or Explanation </label>
                          <p>{data?.data.explanation ? data?.data.explanation : "-" }</p>
                        </div>
                      </div>
                      {/* Explanation end */}
                    </div>
                  </div>
                
                  <div className="col-md-6">
                    <div className="card-body">
                      {/* Answer options Start */}
                      <h5 className="card-title">Response options</h5>
                      <div className="form-group row">
                        <div className="col-sm-12">
                          <label className="control-label col-form-label label-text">Key </label>
                          <p>{data?.data.options[0].option_text ? data?.data.options[0].option_text : "-" }</p>                        
                        </div>
                      </div>

                      <div className="form-group row">
                        <div className="col-sm-12">
                          <label className="control-label col-form-label label-text">Distractor 1 </label>
                          <p>{data?.data.options[1].option_text ? data?.data.options[1].option_text : "-" }</p>
                        </div>
                      </div>

                      <div className="form-group row">
                        <div className="col-sm-12">
                          <label className="control-label col-form-label label-text">Distractor 2 </label>
                          <p>{data?.data.options[2].option_text ? data?.data.options[2].option_text : "-" }</p>
                        </div>
                      </div>

                      <div className="form-group row">
                        <div className="col-sm-12">
                          <label className="control-label col-form-label label-text">Distractor 3 </label>
                          <p>{data?.data.options[3].option_text ? data?.data.options[3].option_text :'-'}</p>
                        </div>
                      </div>
                      {data?.data.options[0].option_media && 
                        <div className="form-group row">
                          <div className="col-sm-11">
                            <label className="control-label col-form-label label-text">Key Attachment</label>
                            <div className="attachment-img">
                              { data?.data.options[0].option_media_type === '1' ?
                                <img  src={assetsURL + "" + data?.data.options[0].option_media} alt="attachment" onClick={() => this.openModal( assetsURL + "" + data?.data.options[0].option_media,"IMAGE")} data-tip='Click to view image' data-place='top' data-effect='solid'/>
                              : data?.data.options[0].option_media_type === '2' ?
                              <img  src="/assets/images/video.png" alt="attachment" onClick={() => this.openModal( assetsURL + "" + data?.data.options[0].option_media,"VIDEO")} data-tip='Click to view video' data-place='top' data-effect='solid'/>
                              : data?.data.options[0].option_media_type === '3' ?
                              <div className="media-pdf">
                                <a target="_blank" rel="noopener noreferrer" href={assetsURL + data?.data.options[0].option_media }>
                                  <img  src="/assets/images/icon-pdf.svg" alt="attachment" data-tip='Click to open pdf' data-place='top' data-effect='solid'/>
                                </a>
                              </div>
                              : ""
                              }                            
                            </div>
                          </div>
                        </div>
                      }
                      
                      { (data?.data.options[1].option_media || data?.data.options[2].option_media || data?.data.options[3].option_media) && 
                        <div className="form-group row">
                          <div className="col-sm-11">
                            <label className="control-label col-form-label label-text">Distractor Attachment</label>
                            <div className="attachment-img">
                              { data?.data.options[1].option_media_type === '1' ?
                                <img  src={assetsURL + "" + data?.data.options[1].option_media} alt="attachment" onClick={() => this.openModal( assetsURL + "" + data?.data.options[1].option_media,"IMAGE")} data-tip='Click to view image' data-place='top' data-effect='solid'/>
                              : data?.data.options[1].option_media_type === '2' ?
                              <img  src="/assets/images/video.png" alt="attachment" onClick={() => this.openModal( assetsURL + "" + data?.data.options[1].option_media,"VIDEO")} data-tip='Click to view video' data-place='top' data-effect='solid'/>
                              : data?.data.options[1].option_media_type === '3' ?
                              <div className="media-pdf">
                                <a target="_blank" rel="noopener noreferrer" href={assetsURL + data?.data.options[1].option_media }>
                                  <img  src="/assets/images/icon-pdf.svg" alt="attachment" data-tip='Click to open pdf' data-place='top' data-effect='solid'/>
                                </a>
                              </div>
                              : ""
                              }

                              { data?.data.options[2].option_media_type === '1' ?
                                <img  src={assetsURL + "" + data?.data.options[2].option_media} alt="attachment" onClick={() => this.openModal( assetsURL + "" + data?.data.options[2].option_media,"IMAGE")} data-tip='Click to view image' data-place='top' data-effect='solid'/>
                              : data?.data.options[2].option_media_type === '2' ?
                              <img  src="/assets/images/video.png" alt="attachment" onClick={() => this.openModal( assetsURL + "" + data?.data.options[2].option_media,"VIDEO")} data-tip='Click to view video' data-place='top' data-effect='solid'/>
                              : data?.data.options[2].option_media_type === '3' ?
                              <div className="media-pdf">
                                <a target="_blank" rel="noopener noreferrer" href={assetsURL + data?.data.options[2].option_media }>
                                  <img  src="/assets/images/icon-pdf.svg" alt="attachment" data-tip='Click to open pdf' data-place='top' data-effect='solid'/>
                                </a>
                              </div>
                              : ""
                              }

                              { data?.data.options[3].option_media_type === '1' ?
                                <img  src={assetsURL + "" + data?.data.options[3].option_media} alt="attachment" onClick={() => this.openModal( assetsURL + "" + data?.data.options[3].option_media,"IMAGE")} data-tip='Click to view image' data-place='top' data-effect='solid'/>
                              : data?.data.options[3].option_media_type === '2' ?
                              <img  src="/assets/images/video.png" alt="attachment" onClick={() => this.openModal( assetsURL + "" + data?.data.options[3].option_media,"VIDEO")} data-tip='Click to view video' data-place='top' data-effect='solid'/>
                              : data?.data.options[3].option_media_type === '3' ?
                              <div className="media-pdf">
                                <a target="_blank" rel="noopener noreferrer" href={assetsURL + data?.data.options[3].option_media }>
                                  <img  src="/assets/images/icon-pdf.svg" alt="attachment" data-tip='Click to open pdf' data-place='top' data-effect='solid'/>
                                </a>
                              </div>
                              : ""
                              }
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                    {/* Answer options End */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    )};

}

export default ViewItem;
