import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import API from '../../api';
import { toast } from 'react-toastify';
import Loader from '../dashboard/loader';
import PropTypes from 'prop-types';
import { Multiselect } from 'multiselect-react-dropdown';
class EditUserForm extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      user_id: '',
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      armdsid: '',
      gender          : '',
      phone           : '',
      certifications  : [],
      specialties     : [],
      working_group : [],
      certifications_selected:[],
      specialties_selected   : [],
      working_group_selected : [],
      certifications_preselected:[],
      specialties_preselected   : [],
      working_group_preselected : [],
      Issubmit: this.props.Issubmit,
      isLoad: this.props.isLoad
    };
    this.validator = new SimpleReactValidator({
      validators: {
        validPassword: {
          message: 'Password must be 8 characters long.',
          rule: (val, params, validator) => {
            return val.length >= 8;
          },
          required: false // optional
        }
      }
    });
  }

  componentWillMount() {
    let self = this;
    this.setState({
      isLoad: true
    });
    API.get(`user/user/${this.props.id}`)
      .then(res => {
        //let fullname  = res.data.data.name; 
        let cert      = res.data.data.certifications.split(",");
        let certifications_preselected = [];
        let specialties_selected = [];
        let working_group_selected = [];
        if(cert.length > 0)
        {
            for(let i=0; i< cert.length; i++)
            {
              certifications_preselected.push(cert[i]);
            }
        }
        
        if(res.data.data.specPreSelected.length > 0)
        {
          
          for(let i=0; i< res.data.data.specPreSelected.length; i++)
            {
              specialties_selected.push(res.data.data.specPreSelected[i]['id']);
            }
        }

        if(res.data.data.wgPreSelected.length > 0)
        {
          
          for(let i=0; i< res.data.data.wgPreSelected.length; i++)
            {
              working_group_selected.push(res.data.data.wgPreSelected[i]['id']);
            }
        }
        
        this.setState({
          user_id     : res.data.data.user_id,
          firstname   : res.data.data.name,
          lastname    : res.data.data.nickname,
          email       : res.data.data.work_email,
          armdsid     : res.data.data.unique_id,
          gender      : res.data.data.gender,
          phone       : res.data.data.work_phone,
          
          specialties     : res.data.data.specialty,
          working_group : res.data.data.working_group,
          certifications : res.data.data.certList,

          certifications_selected : certifications_preselected,
          specialties_selected     : specialties_selected,
          working_group_selected : working_group_selected,
          
          certifications_preselected:(certifications_preselected) ? certifications_preselected : "",
          specialties_preselected     : res.data.data.specPreSelected,
          working_group_preselected : res.data.data.wgPreSelected,
          password:
            res.data.data.password !== false ? res.data.data.password : '',
          isLoad: false
        });
      })
      .catch(function(error) {
        toast.error('Something went wrong on server end.');
        self.setState({
          isLoad: false
        });
      });
  }

  UpdateUser(e, user_id) {
    e.preventDefault();
    if (this.validator.allValid()) {
      this.props.UpdateUser(user_id, this.state);
    } else {
      this.validator.showMessages();
      this.forceUpdate();
      this.setState({
        isLoad: false,
        Issubmit: false
      });
    }
  }

  handlechange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  onSelect(e, name) {
    
    if(name === 'certifications')
    {
      this.setState({
        certifications_selected: e
      });
    }

    if(name === 'specialties')
    {
      let specialties_selected = [];
      if(e.length > 0)
      {
        for(let i=0; i<e.length; i++)
        {
          specialties_selected.push(e[i]['id']);
        }
      }
      this.setState({
        specialties_selected: specialties_selected
      });
    }

    if(name === 'working_group')
    {
      let working_group_selected = [];
      if(e.length > 0)
      {
        for(let i=0; i<e.length; i++)
        {
          working_group_selected.push(e[i]['id']);
        }
      }
      this.setState({
        working_group_selected: working_group_selected
      });
    }
    
  }
 
  onRemove(e, name) {

    if(name === 'certifications')
    {
      this.setState({
        certifications_selected: e
      });
    }

    if(name === 'specialties')
    {
      let specialties_selected = [];
      if(e.length > 0)
      {
        for(let i=0; i<e.length; i++)
        {
          specialties_selected.push(e[i]['id']);
        }
      }
      this.setState({
        specialties_selected: specialties_selected
      });
    }

    if(name === 'working_group')
    {
      let working_group_selected = [];
      if(e.length > 0)
      {
        for(let i=0; i<e.length; i++)
        {
          working_group_selected.push(e[i]['id']);
        }
      }
      this.setState({
        working_group_selected: working_group_selected
      });
    }
  }

  render() {
    return (
      <div>
        <Loader isLoading={this.state.isLoad} />
        <div className='page-wrapper'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='card'>
                  <h4 className='card-title'>Edit User</h4>
                  <div className='col-md-10'>
                    <form className='form-horizontal'>
                      <div className='card-body'>
                        <div className='form-group row'>
                          <div className='col-sm-4'>
                            <label className='text-right control-label col-form-label label-text'>
                              Name<sup className='req-color'>*</sup>
                            </label>

                            <input
                              type='text'
                              className='form-control custom-input'
                              id='fname'
                              value={this.state.firstname}
                              name='firstname'
                              onChange={e => this.handlechange(e)}
                              placeholder='Enter the name'
                            />
                            {this.validator.message(
                              'Name',
                              this.state.firstname,
                              'required|alpha_space|min:1|max:100'
                            )}
                          </div>
                          <div className='col-sm-4'>
                            <label className='control-label col-form-label label-text'>
                              Nick Name<sup className='req-color'>*</sup>
                            </label>

                            <input
                              type='text'
                              className='form-control custom-input'
                              id='lname'
                              value={this.state.lastname}
                              name='lastname'
                              onChange={e => this.handlechange(e)}
                              placeholder='Enter nick name'
                            />
                            {this.validator.message(
                              'Last Name',
                              this.state.lastname,
                              'required|alpha_space|min:2|max:100'
                            )}
                          </div>
                          <div className='col-sm-4'>
                            <label className='control-label col-form-label label-text'>
                              Email<sup className='req-color'>*</sup>
                            </label>

                            <input
                              type='email'
                              className='form-control custom-input'
                              id='email'
                              value={this.state.email}
                              name='email'
                              onChange={e => this.handlechange(e)}
                              placeholder='Enter email address'
                              readOnly
                              autoComplete='off'
                            />
                            {this.validator.message(
                              'Email',
                              this.state.email,
                              'required|email|min:2|max:100'
                            )}
                          </div>
                        </div>
                        <div className='form-group row'>
                          <div className='col-sm-4'>
                            <label className='control-label col-form-label label-text'>
                              ARDMS ID<sup className='req-color'>*</sup>
                            </label>
                            <input
                              type='text'
                              className='form-control custom-input'
                              id='ardmsid'
                              placeholder='Enter ARDMS ID'
                              value={this.state.armdsid}
                              name='armdsid'
                              onChange={e => this.handlechange(e)}
                              readOnly
                              autoComplete='off'
                            />
                            {this.validator.message(
                              ' Ardms Id',
                              this.state.armdsid,
                              'required|numeric|min:1|max:10'
                            )}
                            {/* <input
                              type='password'
                              className='form-control custom-input'
                              id='password'
                              value={this.state.password}
                              name='password'
                              onChange={e => this.handlechange(e)}
                              placeholder='Enter Password'
                              autoComplete='off'
                            />
                            {this.state.password
                              ? this.validator.message(
                                  'Password must be eight characters long.',
                                  this.state.password,
                                  'validPassword'
                                )
                              : ''} */}
                          </div>
                          <div className='col-sm-4'>
                            <label className='control-label col-form-label label-text'>
                              Phone<sup className='req-color'>*</sup>
                            </label>
                            <input
                              type='text'
                              className='form-control custom-input'
                              id='phone'
                              placeholder='Enter phone number'
                              value={this.state.phone}
                              name='phone'
                              onChange={e => this.handlechange(e)}
                            />
                            {this.validator.message(
                              ' Phone',
                              this.state.phone,
                              'required|phone'
                            )}
                          </div>
                          <div className='col-sm-4'>
                            <label className='control-label col-form-label label-text'>
                              Gender<sup className='req-color'>*</sup>
                            </label>
                            <select
                              id="gender"
                              name="gender"
                              style={{backgroundImage: "none"}}
                              placeholder="Please select the gender"
                              className='form-control'
                              onChange={e => this.handlechange(e)} 
                            >
                              <option value="">Please select the gender</option>
                              <option value="1" selected={this.state.gender === '1' ? "selected" : ""}>Male</option>
                              <option value="2" selected={this.state.gender === '2' ? "selected" : ""}>Female</option>
                            </select>
                            {this.validator.message(
                              ' Gender',
                              this.state.gender,
                              'required'
                            )}
                          </div> 
                        </div>
                        <div className='form-group row'>
                          <div className='col-sm-4'>
                            <label className='control-label col-form-label label-text'>
                              Certifications
                            </label>
                            <Multiselect 
                              name="certifications" 
                              id="certifications" 
                              selectedValues={(this.state.certifications_preselected !=='') ? this.state.certifications_preselected : ''}
                              options={this.state.certifications} 
                              isObject={false}
                              onSelect={e => this.onSelect(e,'certifications')} 
                              onRemove={e => this.onRemove(e,'certifications')}
                            />
                            {/* {this.validator.message(
                              ' Certifications',
                              this.state.armdsid,
                              'required'
                            )} */}
                          </div>
                          <div className='col-sm-4'>
                            <label className='control-label col-form-label label-text'>
                              Specialties
                            </label>
                            <Multiselect 
                              name="specialties" 
                              id="specialties" 
                              options={this.state.specialties} 
                              selectedValues={this.state.specialties_preselected}
                              displayValue="name" 
                              dataItemKey="id"
                              onSelect={e => this.onSelect(e,'specialties')} 
                              onRemove={e => this.onRemove(e,'specialties')} 
                            />
                            {/* {this.validator.message(
                              ' Specialties',
                              this.state.phone,
                              'required'
                            )} */}
                          </div>
                          <div className='col-sm-4'>
                            <label className='control-label col-form-label label-text'>
                              Working Group
                            </label>
                            <Multiselect 
                              name="working_group" 
                              id="working_group" 
                              options={this.state.working_group} 
                              selectedValues={this.state.working_group_preselected}
                              displayValue="name"
                              onSelect={e => this.onSelect(e,'working_group')} 
                              onRemove={e => this.onRemove(e,'working_group')}
                            />
                            {/* {this.validator.message(
                              ' Working Group 1',
                              this.state.gender,
                              'required'
                            )} */}
                          </div>  
                        </div>
                      </div>
                      <div className='border-top'>
                        <div className='card-body'>
                          <button
                            type='submit'
                            onClick={e =>
                              this.UpdateUser(e, this.state.user_id)
                            }
                            className='btn btn-primary-newui btn-md-newui'
                            disabled={this.state.Issubmit}
                          >
                            Save
                          </button>
                          <NavLink
                            to='/userslist'
                            type='button'
                            className='btn btn-primary-newui btn-md-newui'
                          >
                            Cancel
                          </NavLink>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
EditUserForm.propTypes = {
  id: PropTypes.string,
  UpdateUser: PropTypes.func,
  Issubmit: PropTypes.bool,
  isLoad: PropTypes.bool
};
export default EditUserForm;
