import API from "../api";
import { GetError } from "../component/action";
export const GET_ALL_ASSESSMENT = "GetAllAssessment";
export function GetAllAssessment() {
  return async function(dispatch, getState) {
    await API.get("item/assessments")
      .then(res => {
        dispatch({ type: GET_ALL_ASSESSMENT, payload: res });        
      })
      .catch(function(error) {
        let errorObj = GetError(error);
        if (errorObj.status === 400) {
          console.log(errorObj.data.message);
        } else if (errorObj.status === 401) {
          console.log(errorObj.data.message);
        } else {
          console.log(errorObj.data.message);
        }
      });
  };
}
