import React from "react";
import "./App.css";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
// import AddUser from "./container/users/add-user";
import { baseURL } from "./component/action";
import Login from "./component/dashboard/login";
import PageNotFound from "./component/dashboard/page-not-found";
import ItemList from "./container/item/item-list";
import ViewItem from "./component/item/view-item";
import ChangePassword from "./container/dashboard/change-password";
import UserList from "./container/users/user-list";
import AddUser from "./container/users/add-user";
import EditUser from "./container/users/edit-user";
import Layout from "./component/dashboard/Layout";
import AssessmentList from "./container/assessment/assessment-list";
import EditAssessment from "./container/assessment/edit-assessment";

import ContactInfoList from "./container/users/contactinfo-list";
import ContactList from "./container/users/contact-list";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function App() {
  return (
    <div>
      <BrowserRouter basename={baseURL}>
        <ToastContainer />
        <Switch>
          <Route
            path="/"
            exact
            render={props => {
              return localStorage.getItem("token") ? (
                <Redirect to="/userslist" />
              ) : (
                <Login {...props} />
              );
            }}
          />
          <Route
            path="/login"
            render={props => {
              return localStorage.getItem("token") ? (
                <Redirect to="/userslist" />
              ) : (
                <Login {...props} />
              );
            }}
          />
          {/* <Route
            path="/dashboard"
            render={props => {
              return localStorage.getItem("token") ? (
                <Layout>
                  <Dashboard {...props} />
                </Layout>
              ) : (
                <Redirect to="/login" />
              );
            }}
          /> */}
          <Route
            path="/userslist"
            render={props => {
              return localStorage.getItem("token") ? (
                <Layout>
                  <UserList {...props} />
                </Layout>
              ) : (
                <Redirect to="/login" />
              );
            }}
          />
          <Route
            path="/adduser"
            render={props => {
              return localStorage.getItem("token") ? (
                <Layout>
                  <AddUser {...props} />
                </Layout>
              ) : (
                <Redirect to="/login" />
              );
            }}
          />
          <Route
            path="/assessmentlist"
            render={props => {
              return localStorage.getItem("token") ? (
                <Layout>
                  <AssessmentList {...props} />
                </Layout>
              ) : (
                <Redirect to="/login" />
              );
            }}
          />
          <Route
            path="/editassessment/:id"
            render={props => {
              return localStorage.getItem("token") ? (
                <Layout>
                  <EditAssessment {...props} />
                </Layout>
              ) : (
                <Redirect to="/login" />
              );
            }}
          />
          <Route
            path="/itemlist"
            render={props => {
              return localStorage.getItem("token") ? (
                <Layout>
                  <ItemList {...props} />
                </Layout>
              ) : (
                <Redirect to="/login" />
              );
            }}
          />

          <Route
            path="/viewitem/:id"
            render={props => {
              return localStorage.getItem("token") ? (
                <Layout>
                  <ViewItem {...props} />
                </Layout>
              ) : (
                <Redirect to="/login" />
              );
            }}
          />

          <Route
            path="/changepassword"
            render={props => {
              return localStorage.getItem("token") ? (
                <Layout>
                  <ChangePassword {...props} />
                </Layout>
              ) : (
                <Redirect to="/login" />
              );
            }}
          />
          <Route
            path="/edituser/:id"
            render={props => {
              return localStorage.getItem("token") ? (
                <Layout>
                  <EditUser {...props} />
                </Layout>
              ) : (
                <Redirect to="/login" />
              );
            }}
          />
          <Route
            path="/contactinfolist"
            render={props => {
              return localStorage.getItem("token") ? (
                <Layout>
                  <ContactInfoList {...props} />
                </Layout>
              ) : (
                <Redirect to="/login" />
              );
            }}
          />
          <Route
            path="/contactlist/:id/:type"
            render={props => {
              return localStorage.getItem("token") ? (
                <Layout>
                  <ContactList {...props} />
                </Layout>
              ) : (
                <Redirect to="/login" />
              );
            }}
          />

          <Route path="" component={PageNotFound} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
