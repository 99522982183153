import React, { Component }  from 'react';
import Loader from '../dashboard/loader';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { NavLink } from 'react-router-dom';
import { Pagination } from '../action';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import logout from '../../component/dashboard/logout';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment'




class ItemListCustom extends Component {

  _exporter;
  export = () => {
    let token = localStorage.getItem('token');
    if (token !== null) {
      this._exporter.save();
    } else {
      logout(this.props.props);
    }
  }; 

 render(){
    console.log(this.props.propsState.sortName);
    return (
      <div>
        <Loader isLoading={this.props.propsState.isLoading} />
        <div className='page-wrapper'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-12'>
                <div className='card'>
                  <h5 className='card-title m-b-0 pl20'>Item List</h5>
                  <div className='card-body'>
                    <div className='row'>
                      <div className='col-md-2' data-select2-id='11'>
                        <b className='filter_head'>Filter by assessment</b>
                        <select
                          className='select2 form-control custom-select select2-hidden-accessible'
                          data-select2-id='1'
                          aria-hidden='true'
                          name='filter_assessment'
                          onChange={e => this.props.handleChange(e)}
                        >
                          <option value=''>Select assessment</option>
                          {this.props.assessment?.assessment &&
                            this.props.assessment?.assessment.map((assessment, index) => {
                                return (
                                  <option
                                    key={index}
                                    value={assessment.assessment_id}
                                  >
                                    {assessment.assessment_name}
                                  </option>
                                );
                              })
                            }
                        </select>
                      </div>
                      <div className='col-md-2' data-select2-id='11'>
                        <b className='filter_head'>Filter by user</b>
                        <select
                          className='select2 form-control custom-select select2-hidden-accessible'
                          data-select2-id='1'
                          aria-hidden='true'
                          name='filter_user'
                          onChange={e => this.props.handleChange(e)}
                        >
                          <option value=''>Select user</option>
                          {this.props?.users &&
                            this.props?.users.map((users, index) => {
                                return (
                                  <option
                                    key={index}
                                    value={users.user_id}
                                  >
                                    {users.name}
                                  </option>
                                );
                              })
                            }
                        </select>
                      </div>

                      <div className='col-md-2' data-select2-id='11'>
                        <b className='filter_head'>Filter by status</b>
                        <select
                          className='select2 form-control custom-select select2-hidden-accessible'
                          data-select2-id='1'
                          aria-hidden='true'
                          name='filter_status'
                          onChange={e => this.props.handleChange(e)}
                        >
                          <option value=''>Select status</option>
                          <option value='1'>Draft</option>
                          <option value='2'>Submitted</option>
                        </select>
                      </div>

                      <div className='col-md-4' data-select2-id='11'>
                        <div className="row">
                          <div className="col-md-6">
                            <b className='filter_head'>Start Date</b>
                              <DatePicker
                                dateFormat="yyyy-MM-dd"
                                selected={this.props.propsState.startDate}
                                onChange={e => this.props.handleChangeStartDate(e)}
                                className='form-control'
                                maxDate={moment(this.props.propsState.endDate).subtract(1, 'days').toDate()}
                              />
                          </div>



                      
                          <div className="col-md-6">
                            <b className='filter_head'>End Date</b>
                            <DatePicker
                              dateFormat="yyyy-MM-dd"
                              selected={this.props.propsState.endDate}
                              onChange={e => this.props.handleChangeEndDate(e)}
                              className='form-control'
                              minDate={moment(this.props.propsState.startDate).add(1, 'days').toDate()}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='col-md-2 text-right' data-select2-id='11'>
                        {this.props.propsState.excel_data.length > 0 ? 
                        <button
                          className='dt-button buttons-excel' onClick={this.export}>
                          <span>Excel</span>
                        </button> 
                        : <button
                          className='dt-button buttons-excel'>
                          <span>Excel</span>
                        </button>
                        }                        
                      </div>


                    </div>
                    <div className='row mt-4'>
                      <div className='col-sm-12 col-md-6'>
                        <div
                          className='dataTables_length'
                          id='zero_config_length'
                        >
                          <label className='table-label'>
                            <span className='table-span'>Show entries</span>
                            <select
                              name='page_size'
                              aria-controls='zero_config'
                              className='form-control form-control-sm'
                              value={this.props.propsState.page_size}
                              onChange={e => this.props.handleChange(e)}
                            >
                              <option value='10'>10</option>
                              <option value='25'>25</option>
                              <option value='50'>50</option>
                              <option value='100'>100</option>
                            </select>
                          </label>
                        </div>
                      </div>
                      <div className='col-sm-12 col-md-6 text-right'>
                        <div
                          id='zero_config_filter'
                          className='dataTables_filter'
                        >
                          <label className='text-left'>
                            <span>Search:</span>
                            <input
                              type='search'
                              className='form-control form-control-sm'
                              placeholder='Search'
                              aria-controls='zero_config'
                              name='searchTerm'
                              id='searchTerm'
                              value={this.props.propsState.searchTerm}
                              onChange={e => this.props.handleChange(e)}
                            />
                          </label>
                        </div>
                      </div>
                    </div>

                    <ExcelExport data={this.props.propsState.excel_data} fileName={ 'item-list-' + Math.floor(Date.now() / 1000) + '.xlsx'} ref={exporter => { this._exporter = exporter; }}>
                      <ExcelExportColumn field='assessment_name' title='Assessment'/>
                      <ExcelExportColumn field='name' title='User'/>    
                      <ExcelExportColumn field='content_outline' title='Content Outline'/>   
                      <ExcelExportColumn field='stem' title='Item'/>                      
                      <ExcelExportColumn field='item_unique_id' title='Item Reference Id'/>
                      <ExcelExportColumn field='options[0].option_text' title='Key'/>
                      <ExcelExportColumn field='options[1].option_text' title='Distractor 1'/>
                      <ExcelExportColumn field='options[2].option_text' title='Distractor 2'/>
                      <ExcelExportColumn field='options[3].option_text' title='Distractor 3'/>
                      <ExcelExportColumn field='reference' title='Rationale/Reference'/>
                      {/* <ExcelExportColumn field='options[0].option_media' title='Key Attachment'/>
                      <ExcelExportColumn field='options[1].option_media' title='Distractor 1 Attachment'/>
                      <ExcelExportColumn field='options[2].option_media' title='Distractor 2 Attachment'/>
                      <ExcelExportColumn field='options[3].option_media' title='Distractor 3 Attachment'/> */}
                      <ExcelExportColumn field='all_media' title='Media File Names'/>
                      <ExcelExportColumn field='item_save_status' title='Status'/>
                      <ExcelExportColumn field='date_submitted' title='Date Submitted'/>                      
                    </ExcelExport>
                    <div className='table-responsive'>
                      <table id='zero_config' className='table'>
                        <thead>
                          <tr>
                            <th
                              width="13%"
                              onClick={() =>
                                this.props.handleSort(
                                  'assessment_name',
                                  this.props.propsState.sortOrder
                                )
                              }
                            >
                              Assessment
                              {this.props.propsState.sortName === 'assessment_name' ? (
                                <span className='sort-active'>
                                  {this.props.propsState.sortOrder === 'asc' ? (
                                    <i className='fas fa-sort-amount-up' />
                                  ) : (
                                    <i className='fas fa-sort-amount-down' />
                                  )}
                                </span>
                              ) : (
                                <span className='sort-inactive'>
                                  <i className='fas fa-sort-amount-up' />
                                </span>
                              )}
                            </th>

                            <th
                              width="15%"
                              onClick={() =>
                                this.props.handleSort(
                                  'name',
                                  this.props.propsState.sortOrder
                                )
                              }
                            >
                              User
                              {this.props.propsState.sortName === 'name' ? (
                                <span className='sort-active'>
                                  {this.props.propsState.sortOrder === 'asc' ? (
                                    <i className='fas fa-sort-amount-up' />
                                  ) : (
                                    <i className='fas fa-sort-amount-down' />
                                  )}
                                </span>
                              ) : (
                                <span className='sort-inactive'>
                                  <i className='fas fa-sort-amount-up' />
                                </span>
                              )}
                            </th>
                            
                            <th
                              width="40%"
                              onClick={() =>
                                this.props.handleSort(
                                  'stem',
                                  this.props.propsState.sortOrder
                                )
                              }
                            >
                              Item
                              {this.props.propsState.sortName === 'stem' ? (
                                <span className='sort-active'>
                                  {this.props.propsState.sortOrder === 'asc' ? (
                                    <i className='fas fa-sort-amount-up' />
                                  ) : (
                                    <i className='fas fa-sort-amount-down' />
                                  )}
                                </span>
                              ) : (
                                <span className='sort-inactive'>
                                  <i className='fas fa-sort-amount-up' />
                                </span>
                              )}
                            </th>
                            <th width="12%" 
                            onClick={() =>
                              this.props.handleSort(
                                'item_unique_id',
                                this.props.propsState.sortOrder
                              )
                            }>
                              Item Ref. Id
                              {this.props.propsState.sortName === 'item_unique_id' ? (
                                <span className='sort-active'>
                                  {this.props.propsState.sortOrder === 'asc' ? (
                                    <i className='fas fa-sort-amount-up' />
                                  ) : (
                                    <i className='fas fa-sort-amount-down' />
                                  )}
                                </span>
                              ) : (
                                <span className='sort-inactive'>
                                  <i className='fas fa-sort-amount-up' />
                                </span>
                              )}
                            </th>
                            <th width="10%" onClick={() => this.props.handleSort( 'item_save_status', this.props.propsState.sortOrder)}>
                              Status
                              {this.props.propsState.sortName === 'item_save_status' ? (
                                <span className='sort-active'>
                                  {this.props.propsState.sortOrder === 'asc' ? (
                                    <i className='fas fa-sort-amount-up' />
                                  ) : (
                                    <i className='fas fa-sort-amount-down' />
                                  )}
                                </span>
                              ) : (
                                <span className='sort-inactive'>
                                  <i className='fas fa-sort-amount-up' />
                                </span>
                              )}
                            </th>
                            <th width="10%">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.props.propsState.isLoading === false ? (
                            this.props.propsState.item_data.length > 0 ? (
                              this.props.propsState.item_data.map(
                                (item, index) => (
                                  <tr key={index} id={item.item_id}>
                                    <td>{item.assessment_name}</td>
                                    <td>{item.name}</td>
                                    <td>{item.stem ? (item.stem) : ( <span style={{ display: 'block', fontSize: 28 }} >-</span>)}</td>
                                    <td>{item.item_unique_id ? item.item_unique_id : '-' }</td>
                                    <td>{item.item_save_status === 'Draft' ? (<span className="badge badge-pill badge-warning">Draft</span>) : (<span className="badge badge-pill badge-success">Submitted</span>) }</td>
                                    <td>
                                      <div>
                                      <ReactTooltip />
                                        <button data-tip='Status' data-place='top' data-effect='solid' className={item.item_status === '1' ? 'btn btn-success btn-sm' : 'btn btn-dark btn-sm'} onClick={() => this.props.changeStatus(item.item_id,item.item_status === '1' ? 2 : 1)}>
                                          <i className={ item.item_status === '1' ? 'fas fa-check' : 'fas fa-times' } />
                                        </button>

                                        <NavLink to={`/viewitem/${item.item_id}`}>
                                          <ReactTooltip />
                                          <button className='btn btn-primary btn-sm' data-tip='View' data-place='top' data-effect='solid'>
                                          <i className='fa fa-eye' />
                                          </button>
                                        </NavLink>

                                        {item.item_save_status !== 'Draft' &&
                                          <a onClick={() => this.props.downloadMedia(item.item_id)} href>
                                            <ReactTooltip />
                                            <button className='btn btn-sm download-btn' data-tip='Download Media' data-place='top' data-effect='solid'>
                                            <i className='fa fa-download' />
                                            </button>
                                          </a>                                      
                                        }
                                      </div>
                                    </td>
                                  </tr>
                                )
                              )
                            ) : (
                              <tr>
                                <td
                                  className='dataTables_empty text-center'
                                  colSpan='6'
                                >
                                  No matching records found
                                </td>
                              </tr>
                            )
                          ) : (
                            <tr>
                              <td
                                className='dataTables_empty text-center'
                                colSpan='6'
                              >
                                Loading...
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className='row mt-4'>
                      <div className='col-sm-12 col-md-5'>
                        <div
                          className='dataTables_info'
                          id='zero_config_info'
                          role='status'
                          aria-live='polite'
                        >
                          {this.props.propsState.isLoading === true
                            ? ''
                            : this.props.propsState.item_data.length === 0
                            ? `Showing 0 of 0 entries`
                            : `Showing  
                            ${
                              this.props.propsState.item_data.length <
                              this.props.propsState.page_size
                                ? this.props.propsState.resultCount
                                : parseInt(
                                    this.props.propsState.item_data.length *
                                      this.props.propsState.currentPage
                                  )
                            }
                            of ${this.props.propsState.resultCount} entries`}
                        </div>
                      </div>
                      <div className='col-sm-12 col-md-7'>
                        <div
                          className='dataTables_paginate paging_simple_numbers float-right'
                          id='zero_config_paginate'
                        >
                          {this.props.propsState.item_data.length > 0 ? (
                            <Pagination
                              noOfPages={this.props.propsState.noOfPages}
                              currentPage={this.props.propsState.currentPage}
                              nextPage={this.props.propsState.nextPage}
                              prevPage={this.props.propsState.prevPage}
                              lastPage={this.props.propsState.lastPage}
                              gotoPage={this.props.gotoPage}
                            />
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};
ItemListCustom.propTypes = {
  afterOpenModal: PropTypes.func,
  closeModal: PropTypes.func,
  handleChange: PropTypes.func,
  handleSort: PropTypes.func,
  openModal: PropTypes.func,
  changeStatus: PropTypes.func,
  gotoPage: PropTypes.func
};




Modal.setAppElement('body');
export default ItemListCustom;
