import React, { Component } from 'react';
import Loader from '../dashboard/loader';
import { Pagination } from '../action';
import PropTypes from 'prop-types';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import logout from '../../component/dashboard/logout';
import { NavLink } from "react-router-dom";
class ContactInfoListCustom extends Component {
  _exporter;
  export = () => {
    let token = localStorage.getItem('token');
    if (token !== null) {
      this._exporter.save();
    } else {
      logout(this.props.props);
    }
  };
  render(){
    return (    
      <div>
        <Loader isLoading={this.props.propsState.isLoading} />
        <div className='page-wrapper'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-12'>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb" style={{ backgroundColor: "#fff", padding:"8px 0px 8px 15px" } }>
                    <li className="breadcrumb-item"><NavLink to="/contactinfolist">Contact Info List</NavLink></li>
                    <li className="breadcrumb-item active" aria-current="page">Contact Detail</li>
                  </ol>
                </nav>
                <div className='card'>
                  <h5 className='card-title m-b-0 pl20'>{this.props.propsState.contactType} Details for <b>{this.props.propsState.userEmail.work_email}</b></h5>
                  <div className='card-body'>
                    <div className='col-md-12 text-right' data-select2-id='11'>                      
                      { this.props.propsState.excel_data.length > 0 ? 
                      <button
                      className='dt-button buttons-excel' onClick={this.export}>
                        <span>Excel</span>
                      </button>
                      : 
                      <button
                      className='dt-button buttons-excel'>
                        <span>Excel</span>
                      </button>
                      }
                      
                    </div>
                  
                    <div className='row'>
                      <div className='col-sm-12 col-md-6'>
                        <div
                          className='dataTables_length'
                          id='zero_config_length'
                        >
                          <label className='table-label'>
                            <span className='table-span'>Show entries</span>
                            <select
                              name='page_size'
                              aria-controls='zero_config'
                              className='form-control form-control-sm'
                              value={this.props.propsState.page_size}
                              onChange={e => this.props.handleChange(e)}
                            >
                              <option value='10'>10</option>
                              <option value='25'>25</option>
                              <option value='50'>50</option>
                              <option value='100'>100</option>
                            </select>
                          </label>
                        </div>
                      </div>
                      <div className='col-sm-12 col-md-6 text-right'>
                        <div
                          id='zero_config_filter'
                          className='dataTables_filter'
                        >
                          <label className='text-left'>
                            <span>Search:</span>
                            <input
                              type='search'
                              className='form-control form-control-sm'
                              placeholder='Search'
                              aria-controls='zero_config'
                              name='searchTerm'
                              id='searchTerm'
                              value={this.props.propsState.searchTerm}
                              onChange={e => this.props.handleChange(e)}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                    
                    <ExcelExport data={this.props.propsState.excel_data} fileName={ 'contact-detail-' + Math.floor(Date.now() / 1000) + '.xlsx'} ref={exporter => { this._exporter = exporter; }}>
                      <ExcelExportColumn field='name' title='Name'/>
                      <ExcelExportColumn field='email' title='Email'/>
                      <ExcelExportColumn field='contact_date' title='Contact Date'/>                    
                    </ExcelExport>
                    <div className='table-responsive'>
                      <table id='zero_config' className='table'>
                        <thead>
                          <tr>
                            <th
                              onClick={() =>
                                this.props.handleSort(
                                  'name',
                                  this.props.propsState.sortOrder
                                )
                              }
                            >
                              User
                              {this.props.propsState.sortName === 'name' ? (
                                <span className='sort-active'>
                                  {this.props.propsState.sortOrder === 'asc' ? (
                                    <i className='fas fa-sort-amount-up' />
                                  ) : (
                                    <i className='fas fa-sort-amount-down' />
                                  )}
                                </span>
                              ) : (
                                <span className='sort-inactive'>
                                  <i className='fas fa-sort-amount-up' />
                                </span>
                              )}
                            </th>
                            
                            <th
                              onClick={() =>
                                this.props.handleSort(
                                  'email',
                                  this.props.propsState.sortOrder
                                )
                              }
                            >
                              Email
                              {this.props.propsState.sortName === 'email' ? (
                                <span className='sort-active'>
                                  {this.props.propsState.sortOrder === 'asc' ? (
                                    <i className='fas fa-sort-amount-up' />
                                  ) : (
                                    <i className='fas fa-sort-amount-down' />
                                  )}
                                </span>
                              ) : (
                                <span className='sort-inactive'>
                                  <i className='fas fa-sort-amount-up' />
                                </span>
                              )}
                            </th>

                            <th
                              onClick={() =>
                                this.props.handleSort(
                                  'contact_date',
                                  this.props.propsState.sortOrder
                                )
                              }
                            >
                              Date
                              {this.props.propsState.sortName === 'contact_date' ? (
                                <span className='sort-active'>
                                  {this.props.propsState.sortOrder === 'asc' ? (
                                    <i className='fas fa-sort-amount-up' />
                                  ) : (
                                    <i className='fas fa-sort-amount-down' />
                                  )}
                                </span>
                              ) : (
                                <span className='sort-inactive'>
                                  <i className='fas fa-sort-amount-up' />
                                </span>
                              )}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.props.propsState.isLoading === false ? (
                            this.props.propsState.users.length > 0 ? (
                              this.props.propsState.users.map((user, index) => (
                                <tr key={index} id={user.user_id}>
                                  <td>{user.name}</td>
                                  <td>{user.email}</td>
                                  <td>{user.contact_date}</td>                                
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td
                                  className='dataTables_empty text-center'
                                  colSpan='3'
                                >
                                  No matching records found
                                </td>
                              </tr>
                            )
                          ) : (
                            <tr>
                              <td
                                className='dataTables_empty text-center'
                                colSpan='3'
                              >
                                Loading...
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className='row mt-4'>
                      <div className='col-sm-12 col-md-5'>
                        <div
                          className='dataTables_info'
                          id='zero_config_info'
                          role='status'
                          aria-live='polite'
                        >
                          {this.props.propsState.isLoading === true
                            ? ''
                            : this.props.propsState.users.length === 0
                            ? `Showing 0 of 0 entries`
                            : `Showing  
                              ${
                                this.props.propsState.users.length <
                                this.props.propsState.page_size
                                  ? this.props.propsState.resultCount
                                  : parseInt(
                                      this.props.propsState.users.length *
                                        this.props.propsState.currentPage
                                    )
                              }
                              of ${this.props.propsState.resultCount} entries`}
                        </div>
                      </div>
                      <div className='col-sm-12 col-md-7'>
                        <div
                          className='dataTables_paginate paging_simple_numbers float-right'
                          id='zero_config_paginate'
                        >
                          {this.props.propsState.users.length > 0 ? (
                            <Pagination
                              noOfPages={this.props.propsState.noOfPages}
                              currentPage={this.props.propsState.currentPage}
                              nextPage={this.props.propsState.nextPage}
                              prevPage={this.props.propsState.prevPage}
                              lastPage={this.props.propsState.lastPage}
                              gotoPage={this.props.gotoPage}
                            />
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

ContactInfoListCustom.propTypes = {
  handleSort: PropTypes.func,
  handleChange: PropTypes.func,
  gotoPage: PropTypes.func
};
export default ContactInfoListCustom;
