import React, { Component } from "react";
import SideNavbar from "../../component/dashboard/side-nav-bar";
class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: "Reports",
      hoverValue: false
    };
  }
  isActive = value => {
    return (
      "sidebar-link has-arrow waves-effect waves-dark " +
      (value === this.state.selected ? "active" : "")
    );
  };
  isActiveUl = value => {
    return (
      "collapse  first-level " + (value === this.state.selected ? "in" : "")
    );
  };
  toggleNav = filter => {
    if (filter === this.state.selected) {
      this.setState({ selected: "" });
    } else {
      this.setState({ selected: filter });
    }
  };
  hoverNav = value => {
    this.props.hoverNav(value);
  };
  render() {
    return (
      <SideNavbar
        toggleNav={this.toggleNav}
        isActive={this.isActive}
        isActiveUl={this.isActiveUl}
        hoverNav={this.hoverNav}
      />
    );
  }
}

export default Sidebar;
