import React, { Component } from "react";
import ContactInfoListCustom from "../../component/users/contactinfo-list-custom";
import API from "../../api";
import { GetError } from "../../component/action";
import { toast } from "react-toastify";
class ContactInfoList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      excel_data: [],
      page_size: 10,
      resultCount: null,
      noOfPages: 1,
      currentPage: 1,
      lastPage: 0,
      prevPage: null,
      nextPage: null,
      pagination: "",
      searchTerm: "",
      sortName: "email",
      sortOrder: "asc",
      isLoading: false
    };
  }

  componentWillMount() {
    this.loadContactList();
  }

  loadContactList = () => {
    const self = this;
    this.setState({
      isLoading: true,
      users: [],
      excel_data: [],
      resultCount: null,
      noOfPages: 1,
      nextPage: null,
      prevPage: null,
      lastPage: 0
    });

    setTimeout(() => {
      API.get(
        `/user/contact_list?order_by=${this.state.sortName}&order=${this.state.sortOrder}&limit=${this.state.page_size}&searchTerm=${this.state.searchTerm}&page=${this.state.currentPage}`
      )
        .then(res => {
          if (res.status === 200) {
            let nextUrl =
              res.data.data.nextlink !== "" ? res.data.data.nextlink : null;
            let prevUrl =
              res.data.data.prevlink !== ""
                ? res.data.data.prevlink === ""
                  ? 1
                  : res.data.data.prevlink
                : null;
            this.setState({
              users: res.data.data.users,
              excel_data: res.data.excel_data,
              resultCount: res.data.data.total_rows,
              isLoading: false,
              noOfPages: Math.ceil(
                res.data.data.total_rows / this.state.page_size
              ),
              nextPage: nextUrl,
              prevPage: prevUrl,
              lastPage: Math.ceil(
                res.data.data.total_rows / this.state.page_size
              )
            });
          }
        })
        .catch(function(error) {
          let errorobj = GetError(error);
          self.setState({ isLoading: false });
          if (errorobj.status === 400) {
            self.setState({ users: [],excel_data: [] });
            if (self.state.users.length > 0) {
              toast.error(errorobj.data.message);
            }
          } else if (errorobj.data.status === 401) {
            toast.error(errorobj.data.message);
          } else {
            toast.error("Something went wrong on server end.");
          }
        });
    }, 100);
  };

  handleSort = (sortName, prevSortOrder) => {
    let sortOrder = prevSortOrder === "asc" ? "desc" : "asc";
    this.setState({ sortName, sortOrder }, () => {
      this.loadContactList();
    });
  };
  handleChange = event => {
    this.setState(
      {
        [event.target.name]: event.target.value,
        currentPage: 1,
        prevPage: null,
        nextPage: this.state.noOfPages > 1 ? 2 : null
      },
      () => {
        this.loadContactList();
      }
    );
  };

  gotoPage = (page, totalPages) => {
    if (page && page !== this.state.currentPage) {
      this.setState(
        {
          currentPage: page
        },
        () => {
          this.loadContactList();
        }
      );
    }
  };

  render() {
    return (
      <ContactInfoListCustom
        handleSort={this.handleSort}
        handleChange={this.handleChange}
        gotoPage={this.gotoPage}
        propsState={this.state}
      />
    );
  }
}

export default ContactInfoList;
