import { GET_ALL_USERS } from "../action/users-action";
const DEFAULT_STATE = "DEFAULT_STATE";
export default function loadUsers(state = [], action) {
  switch (action.type) {
    case GET_ALL_USERS:
      return action.payload.data.data;
    case DEFAULT_STATE:
      return [];
    default:
      return state;
  }
}
